import React, { useState } from 'react'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSingleDataFromDatabase } from '../utils/api'
import DashboardSection from '../components/dashboard/DashboardSection'
import DashboardOverview from '../components/dashboard/DashboardOverview'

const Dashboard = () => {
    const navigate = useNavigate();
    const agentid = localStorage.getItem("agentdata");
    const [agent, setAgent] = useState([]);

    useEffect(() => {
        if (agentid) {
            navigate('/dashboard');
            getDashboardSingleEmpData(agentid);
        }
        else {
            navigate("/");
        }
    }, [agentid]);

    const getDashboardSingleEmpData = (agentid) => {
        getSingleDataFromDatabase(`agent-get-single-age-api-data/${agentid}`).then((res) => {
            if (res.data.status === 1) {
                setAgent(...[res.data.agentdata]);
            }
            else {
                toast.error("Check you connection");
            }
        })
    }

    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:AGENT::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                {(() => {
                    let today = new Date();
                    let curHr = today.getHours();
                    if (curHr < 12) {
                        return (
                            <h4 className="dashboardname">Good Morning, <span>{agent?.agent_name}</span></h4>
                        )
                    }
                    else if (curHr < 17) {
                        return (
                            <h4 className="dashboardname">Good Afternoon, <span>{agent?.agent_name}</span></h4>
                        )
                    }
                    else {
                        return (
                            <h4 className="dashboardname">Good Evening, <span>{agent?.agent_name}</span></h4>
                        )
                    }
                })()}

                <div className="">
                    <DashboardSection />
                </div>
                <div className="">
                    <DashboardOverview />
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Dashboard
