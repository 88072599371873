import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { useNavigate } from 'react-router-dom'
import { getSingleDataFromDatabase, userUploadProfilePicAPI } from '../utils/api'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { BASEAGENTURLS } from '../utils/BaseUrl'
import { MdCloudUpload } from 'react-icons/md'
import moment from 'moment'

const MyProfile = () => {
    const agentid = localStorage.getItem('agentdata');
    const [agent, setAgent] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(false);
    const [file, setFile] = useState('');

    useEffect(() => {
        if (agentid) {
            getDashboardSingleEmpData(agentid);
        }
        else {
            navigate("/");
        }
    }, [agentid]);

    const getDashboardSingleEmpData = (agentid) => {
        setIsloading(true);
        getSingleDataFromDatabase(`agent-get-single-agent-profile-api-data/${agentid}`).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setAgent(...[res.data.agentdata]);
            }
            else {
                setIsloading(true);
                toast.error("Check you connection");
            }
        })
    }
    // Update Image
    useEffect(() => {
        if (file) {
            try {
                const data = new FormData();
                data.append("agent_foto", file);
                userUploadProfilePicAPI(`agent-upload-agent-profile-pic/${agentid}`, data).then((res) => {
                    if (res.data.status === 1) {
                        window.location.reload();
                        toast.success("profile pic updated successfully");
                    }
                    else {
                        toast.error("Unable to fetched picture data");
                    }
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
    }, [file]);
    return (
        <div className="myprofile">
            <Helmet>
                <title>SATKARTAR:CENTER::MY PROFILE</title>
            </Helmet>
            <ContentWrapper>
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-6">
                        <div className="card radius-10 overflow-hidden w-100">
                            <div className="card-header border-bottom bg-transparent">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <h6 className="mb-0" style={{ color: "#03a9f4", fontWeight: "500" }}>My Profile</h6>
                                    </div>
                                </div>
                            </div>
                            {isLoading ?
                                <div className="myprofilesection">
                                    <div className="row">
                                        <div className="col-12 col-lg-4 col-xl-4">
                                            <Skeleton variant="rectangular" width={210} height={218} />
                                        </div>
                                        <div className="col-12 col-lg-8 col-xl-8">
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="myprofilesection">
                                    <div className="row">
                                        <div className="col-12 col-lg-4 col-xl-4">
                                            <div className="profileimg">
                                                <img src={BASEAGENTURLS(agent?.agent_foto)} alt="" />
                                                <label className="labelProfilePic btn btn-sm btn-info" htmlFor="agent_foto"><MdCloudUpload /> Change Profile Picture</label>
                                                <input className="profilePic" type="file" id='agent_foto' name="agent_foto" onChange={(e) => setFile(e.target.files[0])} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-8 col-xl-8">
                                            <div className="row">
                                                <div className="col-12 col-lg-12 col-xl-12">
                                                    <div className="profilebody">
                                                        <span className="key">Name:</span>
                                                        <span className="value">{agent?.agent_name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-12">
                                                    <div className="profilebody">
                                                        <span className="key">EMP ID:</span>
                                                        <span className="value badge badge-success">{agent?.agent_emp_id}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-12">
                                                    <div className="profilebody">
                                                        <span className="key">Center Name:</span>
                                                        <span className="value">{agent?.center_name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-12">
                                                    <div className="profilebody">
                                                        <span className="key">Team Leader Name:</span>
                                                        <span className="value">{agent?.mis_name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-12">
                                                    <div className="profilebody">
                                                        <span className="key">Account Create Date:</span>
                                                        <span className="value">{moment(agent?.agent_create_date).format('Do MMMM YYYY')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default MyProfile