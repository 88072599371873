import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FaBorderAll, FaPlus, FaRegHandPointRight, FaTrashAlt } from 'react-icons/fa';
import { addFormData, fetchAllDataAPI, fetchCityNameByState, fetchPincodeByCity, getDataAPIToPincode, getProductDetailPrice, getSingleDataFromDatabase } from '../utils/api';
import { toast } from 'react-toastify';
import { InputGroup, Table } from 'react-bootstrap';


const initialState = {
    order_name: '',
    order_mobile: '',
    order_alt_mobile: '',
    order_email: '',
    order_pincode: '',
    order_city: '',
    order_state: '',
    order_country: '',
    order_address: '',
    order_language_name: '',
    order_confirm_status: '',
    order_source_name: '',
}

const AddNewOrder = () => {
    const followmobile = localStorage.getItem('followmobile');
    const [validated, setValidated] = useState(false);
    const agentid = localStorage.getItem('agentdata');
    const navigate = useNavigate();
    const [values, setOrderValue] = useState(initialState);
    const [inputvalues, setInputsValue] = useState();
    const [pindata, setPindata] = useState([]);
    const [chanel, setChanel] = useState([]);
    const [dstate, setState] = useState([]);

    const [statename, setStateName] = useState("");
    const [city, setCity] = useState("");
    const [pincode, setPincode] = useState("");

    const [cityname, setCityName] = useState([]);
    const [postname, setPostName] = useState([]);
    const [product, setProduct] = useState([]);
    const [productprice, setProductPrice] = useState([]);
    const [totalprice, setTotalPrice] = useState([]);
    const [finalprice, setFinalPrice] = useState([]);
    const [grandprice, setGrandPrice] = useState([]);
    const [discprice, setDiscPrice] = useState("");
    const [advamts, setAdvAmt] = useState([]);
    const [language, setLanguage] = useState([]);
    const [ostatus, setOrderStatus] = useState([]);
    const [inputlist, setInputList] = useState([{ p_name: '', p_qty: '', p_price: '', p_selling_price: '', p_sub_total: '', p_tsell_price: '', p_disc_price: '' }]);

    useEffect(() => {
        if (agentid) {
            navigate('/agent-new-order');
            getAllActiveChanel();
            getAllDeliveryState();
            getAllActiveProductList();
            getCustomerAddress(followmobile);
            getAllLanguage();
            getAllOrderStatus();
        }
        else {
            navigate("/");
        }
    }, [agentid]);

    const getCustomerAddress = (followmobile) => {
        getSingleDataFromDatabase(`agent-get-customer-address-from-agent/${followmobile}`).then((res) => {
            setOrderValue(...[res.data.single]);
        })
    }
    const handleRowAddClick = () => {
        setInputList([...inputlist, { p_name: '', p_qty: '', p_price: '', p_selling_price: '', p_sub_total: '', p_tsell_price: '', p_disc_price: '' }])
    }
    const handleRowRemoveClick = index => {
        const list = [...inputlist];
        list.splice(index, 1);
        setInputList(list);
    }
    const getAllLanguage = () => {
        fetchAllDataAPI('agent-get-all-language').then((res) => {
            if (res.data.status === 1) {
                setLanguage(...[res.data.language]);
            }
        })
    }
    const getAllOrderStatus = () => {
        fetchAllDataAPI('agent-get-all-order-status').then((res) => {
            if (res.data.status === 1) {
                setOrderStatus(...[res.data.statusdata]);
            }
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                const data = { agent_id: agentid, order_name: values.order_name, order_mobile: values.order_mobile || followmobile, order_alt_mobile: values.order_alt_mobile, order_email: values.order_email, order_source_name: values.order_source_name, order_post_office: values.order_post_office, order_pincode: pincode, order_city: city, order_state: statename, order_country: "India", order_advance_amt: advamts, order_address: values.order_address, sub_total: totalprice + discprice, shipping_charge: 0, payment_method: values.payment_method, total_disc: discprice || 0, total_balance: finalprice, grand_total: grandprice, inputvalues: inputvalues, order_remarks: values.order_remarks, order_language_name: values.order_language_name, order_confirm_status: values.order_confirm_status }
                addFormData('agent-new-order-generate-from-agent', data).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Order has been created successfully!");
                        navigate('/all-orders');
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setValidated(true);
    };
    const handlePincode = e => {
        setPincode(e.target.value)
        const pincode = e.target.value;
        getDataAPIToPincode('agent-get-post-office-name-by-pincode-data', pincode).then((res) => {
            if (res?.data.status === 1) {
                setPostName(...[res?.data.postnamedata]);
            }
            else {
                toast.warning("Check your connection!!");
            }
        })
    }
    const getAllActiveChanel = () => {
        fetchAllDataAPI('agent-get-all-active-chanel-data').then((resp) => {
            if (resp.data.status === 1) {
                setChanel(...[resp.data.achaneldata])
            }
        })
    }
    const getAllDeliveryState = () => {
        fetchAllDataAPI('agent-get-all-delivery-state-data').then((resp) => {
            if (resp.data.status === 1) {
                setState(...[resp.data.statedata])
            }
        })
    }
    const getAllActiveProductList = () => {
        fetchAllDataAPI('agent-get-all-active-product-data').then((resp) => {
            if (resp.data.status === 1) {
                setProduct(...[resp.data.productdata])
            }
        })
    }
    const handleSelectState = (e) => {
        setStateName(e.target.value)
        const statename = e.target.value;
        fetchCityNameByState('agent-get-city-name-by-state-data', statename).then((res) => {
            if (res.data.status === 1) {
                setCityName(...[res.data.citydata])
            }
        })
    }
    const handleSelectCity = (e) => {
        setCity(e.target.value)
        const cityname = e.target.value;
        fetchPincodeByCity('agent-get-pincode-by-city-data', cityname).then((res) => {
            if (res.data.status === 1) {
                setPindata(...[res.data.pincoddata])
            }
        })
    }
    let handleInputs = e => {
        setOrderValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    let handleMultipleInputs = (i, e) => {
        let newFormValues = [...inputlist];
        newFormValues[i][e.target.name] = e.target.value;
        setInputsValue(newFormValues);
    };
    let handleProductSelect = (i, e) => {
        let newProductid = [...inputlist];
        const productid = newProductid[i]['p_name'] = e.target.value;
        setInputsValue(newProductid);
        getProductDetailPrice('agent-get-product-details-data', productid).then((resp) => {
            if (resp.data.status === 1) {
                setProductPrice(...[resp.data.product]);
                newProductid[i][e.target.name] = e.target.value;
                newProductid[i]['p_price'] = resp.data.product.product_price;
                newProductid[i]['p_selling_price'] = resp.data.product.product_sell_price;
                newProductid[i]['p_qty'] = newProductid[i]['p_qty'] || 1;
                newProductid[i]['p_sub_total'] = resp.data.product.product_sell_price * newProductid[i]['p_qty'];
                newProductid[i]['p_tsell_price'] = newProductid[i]['p_sub_total'];
                newProductid[i]['p_disc_price'] = newProductid[i]['p_disc_price'] || 0;
                if (newProductid) {
                    let total = 0;
                    newProductid.map((newProductid, index) => (
                        total += parseInt(newProductid.p_tsell_price)
                    ))
                    setTotalPrice(...[total])
                    setFinalPrice(...[total])
                    setGrandPrice(...[total])
                }
                else {
                    setTotalPrice(...[newProductid[i]['p_sub_total']])
                    setFinalPrice(...[newProductid[i]['p_sub_total']])
                    setGrandPrice(...[newProductid[i]['p_sub_total']])
                }
            }
        })
    }
    let handleQuantitySelect = (i, e) => {
        let newProductid = [...inputlist];
        const productqty = newProductid[i]['p_qty'] = e.target.value;
        if (productqty >= 1) {
            setInputsValue(newProductid);
            newProductid[i]['p_qty'] = productqty;
            newProductid[i]['p_sub_total'] = inputvalues[i]['p_selling_price'] * productqty;
            newProductid[i]['p_tsell_price'] = newProductid[i]['p_sub_total'];
            newProductid[i]['p_disc_price'] = inputvalues[i]['p_selling_price'] * newProductid[i]['p_qty'] - inputvalues[i]['p_tsell_price'];
            let total = 0;
            let dtotal = 0;
            inputvalues.map((inputvalues, index) => (
                total += parseInt(inputvalues.p_tsell_price),
                dtotal += parseInt(inputvalues.p_disc_price)
            ))
            setTotalPrice(...[total])
            setFinalPrice(...[total])
            setGrandPrice(...[total])
            setDiscPrice(...[dtotal])
        }
        else {
            toast.warning("Product quantity must be at least one")
        }

    }
    let handleTotalSellPrice = (i, e) => {
        let newProductid = [...inputlist];
        const productTSellPrice = newProductid[i]['p_tsell_price'] = e.target.value;
        if (productTSellPrice >= 0) {
            setInputsValue(newProductid);
            newProductid[i]['p_tsell_price'] = productTSellPrice;
            newProductid[i]['p_disc_price'] = inputvalues[i]['p_selling_price'] * newProductid[i]['p_qty'] - productTSellPrice;
            let total = 0;
            let dtotal = 0;
            inputvalues.map((inputvalues, index) => (
                total += parseInt(inputvalues.p_sub_total) - parseInt(inputvalues.p_disc_price),
                dtotal += parseInt(inputvalues.p_disc_price)
            ))
            setTotalPrice(...[total])
            setFinalPrice(...[total])
            setGrandPrice(...[total])
            setDiscPrice(...[dtotal])
        }
        else {
            toast.warning("Product quantity must be at least one")
        }

    }
    const handleDiscounts = (e) => {
        const disc = e.target.value
        const finalprice = totalprice - disc;
        setFinalPrice(...[finalprice])
        setDiscPrice(...[disc])
        setGrandPrice(...[finalprice])
    }
    const handleAdvanceAmt = (e) => {
        const advamt = e.target.value
        if (discprice) {
            const finalprice = totalprice - advamt;
            setFinalPrice(...[finalprice])
        }
        else {
            const finalprice = totalprice - advamt;
            setFinalPrice(...[finalprice])
        }
        setAdvAmt(...[advamt])
    }
    const handleSendPaymentLink = () => {
        if (values?.order_name === undefined || followmobile === undefined) {
            toast.warning("Customer name and mobile number should not be blank!")
        }
        else {
            const advdata = { agent_id: agentid, order_name: values?.order_name, order_mobile: followmobile, order_advance_amt: advamts }
            console.log(advdata);
            addFormData('agent-send-payment-link-for-advance-data', advdata).then((resp) => {
                if (resp.data.status === 1) {
                    toast.success(`Amount=>${resp.data.data.amount},merchantId=>${resp.data.data.merchantId},payLink=>${resp.data.data.payLink},transactionId=>${resp.data.data.transactionId},upiIntent
                    =>${resp.data.data.upiIntent
                        }`)
                }
            })
        }
    }
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:AGENT::ADD NEW ORDER</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Add New Order</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12 card cardform marbottom">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <FaBorderAll className="formIcons" />
                                        <h5 className="mb-0 text-danger">ADD New Order</h5>
                                    </div>
                                </div>
                                <hr />
                                <Form noValidate validated={validated} onSubmit={handleSubmit} method="POST">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Customer Name"
                                                name="order_name"
                                                onChange={handleInputs}
                                                value={values?.order_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid customer name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter the Mobile Number"
                                                name="order_mobile"
                                                onChange={handleInputs}
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                                value={values?.order_mobile || followmobile}
                                                readOnly={true}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid customer mobile number
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom03">
                                            <Form.Label>Alternate Mobile Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the Alternate Mobile Number"
                                                name="order_alt_mobile"
                                                onChange={handleInputs}
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                                value={values?.order_alt_mobile}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid customer mobile number
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter the Email address"
                                                name="order_email"
                                                onChange={handleInputs}
                                                value={values?.order_email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid Email Address
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom05">
                                            <Form.Label>Select Chanel OR Source Name</Form.Label>
                                            <Form.Select required
                                                placeholder="Category Name"
                                                name="order_source_name"
                                                onChange={handleInputs}>
                                                <option value="">Select Chanel OR Source Name</option>
                                                {chanel?.map((chanel, index) => (
                                                    <option key={index} value={chanel?.chanel_name}>{chanel?.chanel_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid chanel name or source name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom05">
                                            <Form.Label>Select Language</Form.Label>
                                            <Form.Select required
                                                placeholder="Language Name"
                                                name="order_language_name"
                                                onChange={handleInputs}>
                                                <option value="">Select Language</option>
                                                {language?.map((language, index) => (
                                                    <option key={index} value={language?.language_name}>{language?.language_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid chanel name or source name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom06">
                                            <Form.Label>State</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Enter the State"
                                                name="order_state"
                                                onChange={handleSelectState}>
                                                <option value="">Select State</option>
                                                {dstate?.map((dstate, index) => (
                                                    <option key={index} value={dstate?.state_name}>{dstate?.state_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid state
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom07">
                                            <Form.Label>City/Division</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Enter the City"
                                                name="order_city"
                                                onChange={handleSelectCity}>
                                                <option value="">Select City</option>
                                                {cityname?.map((cityname, index) => (
                                                    <option key={index} value={cityname?.district}>{cityname?.district}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid city
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom08">
                                            <Form.Label>Pincode</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Enter the Pincode"
                                                name="order_pincode"
                                                onChange={handlePincode}>
                                                <option value="">Select Pincode</option>
                                                {pindata?.map((pindata, index) => (
                                                    <option key={index} value={pindata?.pincode}>{pindata?.pincode}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid pincode
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom09">
                                            <Form.Label>Post Office Name</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Enter the Pincode"
                                                name="order_post_office"
                                                onChange={handleInputs}>
                                                <option value="">Select Post Office Name</option>
                                                {postname?.map((postname, index) => (
                                                    <option key={index} value={postname?.office_name}>{postname?.office_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid Post Office Name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom010">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the State"
                                                name="order_country"
                                                value="India"
                                                readOnly={true}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid country
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom011">
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the Remarks"
                                                name="order_remarks"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid country
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom12">
                                            <Form.Label>Select Order Type</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Order Type Name"
                                                name="order_confirm_status"
                                                onChange={handleInputs}>
                                                <option value="">Select Order Type</option>
                                                {ostatus?.map((ostatus, index) => (
                                                    <option key={index} value={ostatus?.ostatus_name}>{ostatus?.ostatus_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid chanel name or source name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" controlId="validationCustom13">
                                            <Form.Label>Full Address</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="text"
                                                placeholder="Full Address"
                                                name="order_address"
                                                onChange={handleInputs}
                                                value={values?.order_address}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid full address
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row className="productdetails table-responsive">
                                        <h6>Product Details</h6>
                                        <Table className="table-sm">
                                            <thead>
                                                <tr className="productdetailhead">
                                                    <th width={300}>Product Name</th>
                                                    <th width={150}>QTY</th>
                                                    <th width={150}>Product Price</th>
                                                    <th width={150}>Selling Price</th>
                                                    <th width={150}>Subtotal Price</th>
                                                    <th width={150}>Total Sell Price</th>
                                                    <th width={150}>Disc Price</th>
                                                    <th width={100}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {inputlist.map((element, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom11${index}`}>
                                                                    <Form.Select
                                                                        required
                                                                        placeholder="Select Product"
                                                                        name="p_name"
                                                                        value={element.p_name || ""}
                                                                        onChange={e => handleProductSelect(index, e)}>
                                                                        <option value="">Select Product</option>
                                                                        {product?.map((product, index) => (
                                                                            <option key={index} value={product?.product_id}>{product?.product_name}</option>
                                                                        ))}

                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom12${index}`}>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="Product QTY"
                                                                        name="p_qty"
                                                                        pattern="[0-9]*"
                                                                        minLength={1}
                                                                        onChange={e => handleQuantitySelect(index, e)}
                                                                        value={element.p_qty || ""}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom13${index}`}>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="Product Price"
                                                                        name="p_price"
                                                                        pattern="[0-9]*\.?[0-9]*"
                                                                        readOnly={true}
                                                                        onChange={e => handleMultipleInputs(index, e)}
                                                                        value={element.p_price || ""}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom14${index}`}>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="Selling Price"
                                                                        name="p_selling_price"
                                                                        pattern="[0-9]*\.?[0-9]*"
                                                                        readOnly={true}
                                                                        onChange={e => handleMultipleInputs(index, e)}
                                                                        value={element.p_selling_price || ""}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom15${index}`}>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="Sub Total Price"
                                                                        name="p_sub_total"
                                                                        pattern="[0-9]*\.?[0-9]*"
                                                                        readOnly={true}
                                                                        onChange={e => handleMultipleInputs(index, e)}
                                                                        value={element.p_sub_total || ""}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom15${index}`}>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="Total Sell Price"
                                                                        name="p_tsell_price"
                                                                        pattern="[0-9]*\.?[0-9]*"
                                                                        onChange={e => handleTotalSellPrice(index, e)}
                                                                        value={element.p_tsell_price || ""}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom15${index}`}>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="0.00"
                                                                        name="p_disc_price"
                                                                        readOnly={true}
                                                                        pattern="[0-9]*\.?[0-9]*"
                                                                        value={element.p_disc_price || ""}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            {(() => {
                                                                if (inputlist.length !== index + 1) {
                                                                    return (
                                                                        <td>
                                                                            <span className="btnremoverow" onClick={() => handleRowRemoveClick(index)}><FaTrashAlt /> Remove</span>
                                                                        </td>
                                                                    )
                                                                }
                                                            })()}
                                                            {(() => {
                                                                if (inputlist.length - 1 === index) {
                                                                    return (
                                                                        <td>
                                                                            <span className="btnaddrow" onClick={handleRowAddClick}><FaPlus /> Add More</span>
                                                                        </td>
                                                                    )
                                                                }
                                                            })()}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </Row>
                                    <Row>
                                        <div className="col-md-4 offset-xxl-8 pricedetails">
                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Sub Total(₹)</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        placeholder="0.00"
                                                        name="sub_total"
                                                        value={totalprice + discprice}
                                                        readOnly={true}
                                                        onChange={handleInputs}
                                                        pattern="[0-9]*\.?[0-9]*"
                                                    />
                                                </InputGroup>
                                            </div>
                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Shipping Charge(₹)</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        placeholder="0.00"
                                                        name="shipping_charge"
                                                        onChange={handleInputs}
                                                        defaultValue={0}
                                                        readOnly={true}
                                                        pattern="[0-9]*\.?[0-9]*"
                                                    />
                                                </InputGroup>
                                            </div>
                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Payment Method</InputGroup.Text>
                                                    <Form.Select
                                                        required
                                                        placeholder="Select Product"
                                                        name="payment_method"
                                                        onChange={handleInputs}>
                                                        <option value="">Payment Method</option>
                                                        <option value="COD">COD</option>
                                                        <option value="ONLINE">ONLINE</option>
                                                    </Form.Select>
                                                </InputGroup>
                                            </div>
                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Discount(₹)</InputGroup.Text>
                                                    <Form.Control
                                                        required
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        placeholder="0.00"
                                                        name="total_disc"
                                                        readOnly={true}
                                                        onChange={handleDiscounts}
                                                        pattern="[0-9]*\.?[0-9]*"
                                                        value={discprice || 0}
                                                    />
                                                </InputGroup>
                                            </div>
                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Adcance Amount(₹)</InputGroup.Text>
                                                    <Form.Control
                                                        required
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        placeholder="0.00"
                                                        name="order_advance_amt"
                                                        onChange={handleAdvanceAmt}
                                                        pattern="[0-9]*\.?[0-9]*"
                                                    />
                                                </InputGroup>
                                            </div>
                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Total Balance(₹)</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        placeholder="0.00"
                                                        name="total_balance"
                                                        value={finalprice}
                                                        readOnly={true}
                                                        onChange={handleInputs}
                                                        pattern="[0-9]*\.?[0-9]*"
                                                    />
                                                </InputGroup>
                                            </div>
                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Grand Total(₹)</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        placeholder="0.00"
                                                        name="grand_total"
                                                        value={grandprice}
                                                        readOnly={true}
                                                        onChange={handleInputs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </div>
                                    </Row>
                                    <div className="mt-2 btnorders">
                                        {(() => {
                                            if (advamts != "" && advamts >= 0 && advamts != 0) {
                                                return (
                                                    <span onClick={handleSendPaymentLink} className="btn btn-sm btn-secondary">Send Payment Link</span>
                                                )
                                            }
                                        })()}
                                        <Button type="submit" className="btn btn-sm btn-success">Generate Order</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddNewOrder