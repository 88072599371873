import React, { useEffect, useState } from 'react'
import { FaBasketballBall, FaBuffer } from 'react-icons/fa'
import { MdCalendarMonth, MdOutlineCalendarMonth } from "react-icons/md";
import { IoToday } from "react-icons/io5";
import { toast } from 'react-toastify';
import { fetchAllAgentDataAPI } from '../../utils/api';

const DashboardSection = () => {
    const agentid = localStorage.getItem("agentdata");
    const [todayorder, setTodayOrder] = useState([]);
    const [yesterdayorder, setYesterdayOrder] = useState([]);
    const [thismonth, setThisMonthOrder] = useState([]);
    const [lastmonth, setLastMonthOrder] = useState([]);
    const [totalorder, setTotalOrder] = useState([]);
    

    useEffect(() => {
        getDashboardAllOrderTypeAgentOrder(agentid);
    }, [agentid])

    const getDashboardAllOrderTypeAgentOrder = (agentid) => {
        fetchAllAgentDataAPI('agent-get-dashboard-overview-order-type-data', agentid).then((res) => {
            if (res.data.status === 1) {
                setTodayOrder(...[res.data.todayorder]);
                setYesterdayOrder(...[res.data.yesterdayorder]);
                setThisMonthOrder(...[res.data.thismonth]);
                setLastMonthOrder(...[res.data.lastmonth]);
                setTotalOrder(...[res.data.totalorder]);
            }
            else {
                toast.warning("Check your connection")
            }
        })
    }
    return (
        <div className="dashboardsection">
            <div className="card radius-10 overflow-hidden w-100" style={{ background: "#4e9191" }}>
                <div className="icons">
                    <IoToday />
                </div>
                <div className="ordertype">
                    <span>Today Orders</span>
                </div>
                <div className="ordervalue">
                    <h2>{todayorder}</h2>
                </div>
            </div>
            <div className="card radius-10 overflow-hidden w-100" style={{ background: "#0e7c52" }}>
                <div className="icons">
                    <FaBuffer />
                </div>
                <div className="ordertype">
                    <span>Yesterday Orders</span>
                </div>
                <div className="ordervalue">
                    <h2>{yesterdayorder}</h2>
                </div>
            </div>
            <div className="card radius-10 overflow-hidden w-100" style={{ background: "#00CED1" }}>
                <div className="icons">
                    <MdCalendarMonth />
                </div>
                <div className="ordertype">
                    <span>This Months Orders</span>
                </div>
                <div className="ordervalue">
                    <h2>{thismonth}</h2>
                </div>
            </div>
            <div className="card radius-10 overflow-hidden w-100" style={{ background: "#c779db" }}>
                <div className="icons">
                    <MdOutlineCalendarMonth />
                </div>
                <div className="ordertype">
                    <span>Last Months Orders</span>
                </div>
                <div className="ordervalue">
                    <h2>{lastmonth}</h2>
                </div>
            </div>
            <div className="card radius-10 overflow-hidden w-100" style={{ background: "#597e0d" }}>
                <div className="icons">
                    <FaBasketballBall />
                </div>
                <div className="ordertype">
                    <span>Total Order</span>
                </div>
                <div className="ordervalue">
                    <h2>{totalorder}</h2>
                </div>
            </div>
        </div>
    )
}

export default DashboardSection